* {
	margin: 0;
	padding: 0;
}

#root {
	display: flex;
	flex-direction: column;
	flex: 1 1 0;
}

body {
	display: flex;
	flex-direction: column;
	margin: 0;
	/* backup if -webkit-fill-available is not available */
	min-height: 100vh;
	/* mobile viewport bug fix */
	min-height: -webkit-fill-available;
}

html {
	margin: 0;
	height: -webkit-fill-available;
}